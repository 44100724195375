import React from 'react';
import {
  arrayOf,
  object,
  string,
  shape,
  number,
} from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({
  description,
  lang,
  meta,
  image: metaImage,
  title,
}) => {
  const { site, settingsData } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        settingsData: allMarkdownRemark(filter: {
          frontmatter: {
            templateKey: { eq: "settings" }
          }
        }) {
          edges {
            node {
              frontmatter {
                siteDescription
                siteTitle
                siteKeywords
                siteLogo {
                  childImageSharp {
                    fluid(maxWidth: 500) {
                      src
                      presentationHeight
                      presentationWidth
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  );
  const settings = settingsData.edges[0].node.frontmatter;

  const metaDescription = description || settings.siteDescription;

  // the image url has to be an absolute url with http:// or https://
  // relative links do not work
  const image = metaImage && metaImage.src
    ? site.siteMetadata.siteUrl.concat(metaImage.src)
    : site.siteMetadata.siteUrl.concat(settings.siteLogo.childImageSharp.fluid.src);

  return (
    <Helmet
      htmlAttributes={ { lang } }
      title={ title }
      titleTemplate={ `%s | ${settings.siteTitle}` }
      meta={ [
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: settings.siteKeywords.join(','),
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: image,
        },
        {
          property: 'og:image:width',
          content: settings.siteLogo.childImageSharp.fluid.presentationWidth,
        },
        {
          property: 'og:image:height',
          content: settings.siteLogo.childImageSharp.fluid.presentationHeight,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:creator',
          content: settings.siteTitle,
        },
      ]
        .concat(meta) }
    />
  );
};

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  image: {
    src: '',
    height: 0,
    width: 0,
  },
};

SEO.propTypes = {
  description: string,
  lang: string,
  meta: arrayOf(object),
  title: string.isRequired,
  image: shape({
    src: string.isRequired,
    height: number.isRequired,
    width: number.isRequired,
  }),
};

export default SEO;
