import React, { useState } from 'react';
import {
  string, array,
} from 'prop-types';

import clsx from 'clsx';
import { Link } from 'gatsby';

import Navbar from 'Components/Navbar';
import headerLogo from 'Assets/switch_laboratory.svg';

const Header = ({ siteTitle, navbar }) => {
  const [ menuOpen, setMenuOpen ] = useState(false);
  const toggleHandler = () => setMenuOpen(!menuOpen);


  return (
    <div
      className={ clsx('header__wrapper', {
        'is-open': menuOpen,
      }) }
    >
      <div className="header-content">
        <Link to="/">
          <img className="header-logo" src={ headerLogo } alt={ siteTitle } />
        </Link>
        <button className="header-nav__toggle" type="button" onClick={ toggleHandler }>
          <span className="header-nav__toggle__icon" />
        </button>
        <Navbar items={ navbar } />
      </div>
    </div>
  );
};

Header.propTypes = {
  siteTitle: string,
  // eslint-disable-next-line react/forbid-prop-types
  navbar: array.isRequired,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
