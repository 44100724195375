import React from 'react';
import clsx from 'clsx';
import {
  arrayOf,
  oneOfType,
  number,
  shape,
  string,
} from 'prop-types';

import { Link } from 'gatsby';

const Navbar = ({ items }) => {
  const getLinkProps = ({
    href,
    isCurrent,
    isPartiallyCurrent,
    location,
  }) => ({
    className: clsx('header-nav__item-link', {
      'header-nav__item-link--is-current': isCurrent,
      'header-nav__item-link--is-partially-current': (href !== '/' || location.pathname === '/') && isPartiallyCurrent,
    }),
  });

  return (
    <nav className="header-nav__wrapper">
      <ul className="header-nav">
        { items.map(menuItem => (
          <li
            className="header-nav__item"
            key={ `menu-item-${menuItem.linkURL}` }
          >
            { menuItem.linkType === 'internal'
              ? (
                <>
                  <Link key={ menuItem.linkURL } to={ menuItem.linkURL } getProps={ getLinkProps }>
                    { menuItem.label }
                  </Link>
                  { !!menuItem.submenu && (
                    <nav className="header-subnav__wrapper">
                      <ul className="header-subnav">
                        { menuItem.submenu.map(submenuItem => (
                          <li
                            className="header-nav__item"
                            key={ `sub-menu-item-${submenuItem.linkURL}` }
                          >
                            { submenuItem.linkType === 'internal'
                              ? (
                                <Link key={ submenuItem.linkURL } to={ submenuItem.linkURL } getProps={ getLinkProps }>
                                  { submenuItem.label }
                                </Link>
                              )
                              : (
                                <a key={ submenuItem.linkURL } href={ submenuItem.linkURL }>
                                  { submenuItem.label }
                                </a>
                              ) }
                          </li>
                        )) }
                      </ul>
                    </nav>
                  ) }
                </>
              )
              : (
                <a key={ menuItem.linkURL } href={ menuItem.linkURL }>
                  { menuItem.label }
                </a>
              ) }
          </li>
        )) }
      </ul>
    </nav>
  );
};

Navbar.propTypes = {
  items: arrayOf(shape({
    label: string.isRequired,
    linkType: string.isRequired,
    linkURL: string.isRequired,
    submenu: oneOfType([ number, arrayOf(shape({
      label: string.isRequired,
      linkType: string.isRequired,
      linkURL: string.isRequired,
    })) ]),
  })).isRequired,
};

export default Navbar;
