import React from 'react';
import { Link } from 'gatsby';
import footerLogo from 'Assets/switch_laboratory_white.svg';

import {
  string,
  object,
  shape,
  arrayOf,
} from 'prop-types';

const Footer = ({ siteTitle, copyright, logos }) => (
  <div className="footer__wrapper">
    <span className="footer__copyright">
      { copyright }
    </span>
    <div className="footer__partner-logos">
      { logos.map(logo => (
        <a key={ logo.linkURL } href={ logo.linkURL } className="footer__partner-logo" target="_blank" rel="noopener noreferrer">
          <img src={ logo.image.childImageSharp.original.src } />
        </a>
      )) }
    </div>

    <div className="footer__sl-logo">
      <Link to="/">
        <img className="footer__logo" src={ footerLogo } alt={ siteTitle } />
      </Link>
    </div>
  </div>
);

Footer.propTypes = {
  logos: arrayOf(shape({
    linkURL: string.isRequired,
    image: object,
  })),
  copyright: string,
  siteTitle: string,
};

Footer.defaultProps = {
  copyright: '',
  logos: [],
  siteTitle: '',
};

export default Footer;
